exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-ami-news-index-jsx": () => import("./../../../src/pages/ami-news/index.jsx" /* webpackChunkName: "component---src-pages-ami-news-index-jsx" */),
  "component---src-pages-amicorp-group-index-jsx": () => import("./../../../src/pages/amicorp-group/index.jsx" /* webpackChunkName: "component---src-pages-amicorp-group-index-jsx" */),
  "component---src-pages-banking-services-disclaimer-jsx": () => import("./../../../src/pages/banking-services-disclaimer.jsx" /* webpackChunkName: "component---src-pages-banking-services-disclaimer-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-case-studies-index-jsx": () => import("./../../../src/pages/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-esg-policy-jsx": () => import("./../../../src/pages/esg-policy.jsx" /* webpackChunkName: "component---src-pages-esg-policy-jsx" */),
  "component---src-pages-globalization-aglo-me-corridor-index-jsx": () => import("./../../../src/pages/globalization/aglo-me-corridor/index.jsx" /* webpackChunkName: "component---src-pages-globalization-aglo-me-corridor-index-jsx" */),
  "component---src-pages-globalization-business-expansion-services-southeast-asia-index-jsx": () => import("./../../../src/pages/globalization/business-expansion-services-southeast-asia/index.jsx" /* webpackChunkName: "component---src-pages-globalization-business-expansion-services-southeast-asia-index-jsx" */),
  "component---src-pages-globalization-contact-index-jsx": () => import("./../../../src/pages/globalization/contact/index.jsx" /* webpackChunkName: "component---src-pages-globalization-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-offices-index-jsx": () => import("./../../../src/pages/offices/index.jsx" /* webpackChunkName: "component---src-pages-offices-index-jsx" */),
  "component---src-pages-our-impact-index-jsx": () => import("./../../../src/pages/our-impact/index.jsx" /* webpackChunkName: "component---src-pages-our-impact-index-jsx" */),
  "component---src-pages-our-people-index-jsx": () => import("./../../../src/pages/our-people/index.jsx" /* webpackChunkName: "component---src-pages-our-people-index-jsx" */),
  "component---src-pages-privacy-notice-jsx": () => import("./../../../src/pages/privacy-notice.jsx" /* webpackChunkName: "component---src-pages-privacy-notice-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-terms-of-business-jsx": () => import("./../../../src/pages/terms-of-business.jsx" /* webpackChunkName: "component---src-pages-terms-of-business-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-templates-career-detail-career-detail-jsx": () => import("./../../../src/templates/CareerDetail/CareerDetail.jsx" /* webpackChunkName: "component---src-templates-career-detail-career-detail-jsx" */),
  "component---src-templates-casestudy-detail-casestudy-detail-jsx": () => import("./../../../src/templates/CasestudyDetail/CasestudyDetail.jsx" /* webpackChunkName: "component---src-templates-casestudy-detail-casestudy-detail-jsx" */),
  "component---src-templates-news-detail-news-detail-jsx": () => import("./../../../src/templates/NewsDetail/NewsDetail.jsx" /* webpackChunkName: "component---src-templates-news-detail-news-detail-jsx" */),
  "component---src-templates-office-detail-office-detail-jsx": () => import("./../../../src/templates/OfficeDetail/OfficeDetail.jsx" /* webpackChunkName: "component---src-templates-office-detail-office-detail-jsx" */),
  "component---src-templates-service-location-service-location-jsx": () => import("./../../../src/templates/ServiceLocation/ServiceLocation.jsx" /* webpackChunkName: "component---src-templates-service-location-service-location-jsx" */),
  "component---src-templates-services-detail-services-detail-jsx": () => import("./../../../src/templates/ServicesDetail/ServicesDetail.jsx" /* webpackChunkName: "component---src-templates-services-detail-services-detail-jsx" */),
  "component---src-templates-subservices-subservices-jsx": () => import("./../../../src/templates/Subservices/Subservices.jsx" /* webpackChunkName: "component---src-templates-subservices-subservices-jsx" */)
}

